export const iconsStatusColors = {
  'CRITICAL_ERROR': "#f44336",
  'THROWN_ERROR': "#f44336",
  'SUCCESS': "#66bb6a",
  'FAILED': "#ffa726",
  'DEBUG': "grey.500",
//'emergency', 'alert', 'critical', 'error', 'warning', 'notice', 'info', 'success', 'debug'
  'emergency': "#920b00",
  'critical': "#920b00",
  'error': "#f44336",
  'success': "#66bb6a",
  'alert': "#d3ca00",
  'info': "#29b6f6",
  'notice': "#683500",
  'warning': "#ffa726",
  'debug': "grey.500",
}
export const getStatues = (column: string[]) => {
  let codex = {};


  column.map((st, index) => {
    codex[st] = {
      w: 5,
      h: 5,
      color: iconsStatusColors[st]
    }
    // codex[st] = <CircleIcon key={index} w={5} h={5} color={icons[st]}/>;
  })

  return Object.values(codex)

}