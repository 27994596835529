// import {getEnv} from "utils";
// import getUrl from "../utils/axios/getUrl.ts";

export type Environment = "production" | "test"

// const baseUrl = getUrl();


const authBaseURL = 'auth/';
const logsBaseURL = 'logs/';
const logBaseURL = 'log/';

export const URLs = {
    // Auth
    login: authBaseURL + "login",
    logout: authBaseURL + "logout",
    downloadFile: ($file_path: string) => 'download/' + $file_path,
    getCompanyApplicationPortalLogs: logsBaseURL + 'company',
    getBackendPortalLogs: logsBaseURL + 'manage',
    getAdminPortalLogs: logsBaseURL + 'admin',
    getLogDetails: (appID: string) =>
        logBaseURL + 'details/' + appID,
    updateLog: (appID: string) =>
        logBaseURL + 'solve/' + appID,
    syncLogs: (appName: 'admin' | 'manage' | 'company') =>
        logsBaseURL + 'sync/' + appName,
}


