import React, {FunctionComponent} from 'react';
import {Flex} from "@chakra-ui/react";

interface OwnProps {
}

type Props = OwnProps;

const Dashboard: FunctionComponent<Props> = (props) => {
    return (
        <Flex height={'100%'} justifyContent={'flex-start'} alignItems={'flex-start'} flexDirection={'column'}>


            {/*<Flex height={'50%'} justifyContent={'flex-start'} alignItems={'flex-start'}>*/}
            {/*<Cards
                numberOfItemsOnView={3}
                label={'Admin Resources'}
                onAcceptClick={() => {

                }}
                onRejectClick={(row) => {

                }}
                onAssignToClick={(row) => {

                }}
                onLeaveCommentClick={() => {

                }}
                data={requests}/>*/}

            {/*</Flex>*/}

            {/*            <Flex height={'50%'} justifyContent={'flex-start'} alignItems={'flex-start'}>
                <HierarchyView label={'Admin Resources'} data={dataFetched} checklist={true} expendable={true}
                               openedByDefault={false}/>
            </Flex>*/}


        </Flex>
    );
};

export default Dashboard;
