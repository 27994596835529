import React from "react";
import { IMenuItem } from "@gomarketsapp/ready-steady-go";
import {
    ApplicationBackendPortal,
    AdminPortal,
    Dashboard,
    LogDetails,
} from 'pages';
import { MdManageAccounts } from "react-icons/md";
import { IoIosHome } from "react-icons/io";
import { IoSettings } from "react-icons/io5";
import CompanyApplicationPortal from "../pages/CompanyApplicationPortal";

const useMenuList = () => {

    const detailsLink = {
        title: "Log Details",
        hide: true,
        path: ":table/:id",
        element: <LogDetails />
    }

    const homeLinks: IMenuItem = {
        title: "Homepage",
        icon: <IoIosHome fontSize={20} />,
        path: "*",
        element: <Dashboard />
    };

    const adminRoutes: IMenuItem = {
        title: "Admin Portal",
        icon: <IoSettings fontSize={20} />,
        path: "admin",
        element: <AdminPortal />,
    };

    const manageAppRoutes: IMenuItem = {
        title: "Manage App",
        icon: <MdManageAccounts fontSize={20} />,
        path: "manage",
        element: <ApplicationBackendPortal />,
    };

    const companyAppRoutes: IMenuItem = {
        title: "Company App",
        icon: <MdManageAccounts fontSize={20} />,
        path: "company",
        element: <CompanyApplicationPortal />,
    };


    const tabs: IMenuItem[] = [
        homeLinks,
        adminRoutes,
        manageAppRoutes,
        companyAppRoutes,
        detailsLink
    ];

    return { tabs };
};
export default useMenuList;
