import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Center, Flex, GridItem} from '@gomarketsapp/ready-steady-go';
import {LogProps} from 'models';
import {logsService} from 'services';
import {Alert} from './Alert';
import './style.scss';
import {status,} from "utils";
import LogItem from "./LogItem";
import {Timeline} from "primereact/timeline";
import {Badge} from "primereact/badge";
import {Spinner} from "@chakra-ui/react";
import LogData from "./LogData";


const LogDetailsPage = () => {
    const {table, id} = useParams();

    const [selectedLog, setSelectedLog] = useState<LogProps | null>(null)

    const [logLine, setLogLine] = useState<LogProps[] | null>(
        null
    );

    const {AlertDialog} = Alert();


    const {retrieveLogDetails} = {
        ...logsService
    };

    const refreshData = async () => {
        if (id != null && table != null) {
            const res = await retrieveLogDetails(table, id);


            if (res.success) {
                const logs = res?.data as LogProps[];
                setLogLine(logs);
            }
        }
    };

    useEffect(() => {
        refreshData().then(() => {
            // successAlert({
            //   message: 'Application was refreshed'
            // });
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const events = !logLine && !Array.isArray(logLine) ? [] : (logLine as LogProps[]);

    const marker = (log: LogProps, index: number) => {

        switch (log[status]) {
            case 'success':
                return <Badge key={index} size={'normal'} severity={'success'} value={'S'}/>
            case 'critical':
                return <Badge key={index} size={'normal'} severity={'danger'} value={'CR'}/>
            case 'error':
                return <Badge key={index} size={'normal'} severity={'danger'} value={'ER'}/>
            case 'emergency':
                return <Badge key={index} size={'normal'} severity={'danger'} value={'EM'}/>
            case 'info':
                return <Badge key={index} size={'normal'} severity={'info'} value={'I'}/>
            case 'alert':
                return <Badge key={index} size={'normal'} severity={'info'} value={'A'}/>
            case 'notice':
                return <Badge key={index} size={'normal'} severity={'info'} value={'N'}/>
            case 'debug':
                return <Badge key={index} size={'normal'} severity={'warning'} value={'D'}/>

        }
        return <Badge size={'normal'} severity={'danger'} value={''}/>
    }


    return !logLine ? (
        <GridItem colSpan={12}>
            <Center>
                <Flex direction="column" gap={4} alignItems="center">
                    <Spinner
                        thickness="5px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="xl"
                        mr={2}
                    />
                    Retrieving Log Details
                </Flex>
            </Center>
        </GridItem>
    ) : (
        <>
            <style dangerouslySetInnerHTML={{
                __html: `
        #page-contents-body {
          overflow: hidden;
        }
      `
            }}/>

            <Flex w={'full'} overflow={'hidden'} h={'screen'}>
                <Flex w={'45%'} overflow={'scroll'} pt={10}>
                    <Timeline
                        value={events}
                        marker={marker}
                        align="right"
                        pt={{
                            root: {
                                style: {
                                    width:'100%'
                                }
                            },
                            event: {
                                style: {
                                    minHeight: '300px',
                                    width:'100%'
                                }
                            },
                            content: {
                                style: {
                                    width:'93%'
                                }
                            }
                        }}
                        content={(log: LogProps, index: number) => <LogItem
                            key={index}
                            onReadButton={() => {
                                setSelectedLog(log)
                            }}
                            index={index}
                            log={log}/>}
                    />
                </Flex>
                <Flex w={'55%'} overflow={'hidden'}>

                    {!!selectedLog && <LogData log={selectedLog}/>}
                </Flex>
                <AlertDialog/>
            </Flex>
        </>
    );
};

export default LogDetailsPage;
