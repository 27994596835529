import React, {FC} from 'react';
import {logsService, URLs} from 'services';
import {Heading} from '@chakra-ui/react';
import LogsTable from "../LogsTable";

const ApplicationBackendPortalLIVE: FC = () => {
    const {retrieveCompanyApplicationLogs} = logsService;

    const fetcherKey = `${URLs.getCompanyApplicationPortalLogs}` as string;

    return (
        <>
            <Heading size="lg" mb={4}>
                Company Application Portal Logs
            </Heading>
            <LogsTable fetcherKey={fetcherKey}
                       service={(paginationOptions) => retrieveCompanyApplicationLogs(paginationOptions, ['groups'])}
                       platformKey={'company'}/>
        </>
    );
};

export default ApplicationBackendPortalLIVE;
