import React, { FC, useMemo } from "react";
import {getUrl, ReadySteadyProvider } from "@gomarketsapp/ready-steady-go";
import useMenu from "./routes";
import { authService } from "./services";

const App: FC = () => {
    const { tabs } = useMenu();
    // const { setColorMode, colorMode } = useColorMode();

    const baseUrl = getUrl();

    const AppProvider = useMemo(() => <ReadySteadyProvider
        autoLogin={true}
        loginRoute={`${baseUrl}/login`}
        logoutRoute={`${baseUrl}/logout`}
        logoutCallback={()=>{
            localStorage.clear()
        }}
        routes={tabs}
        caching={false}
        loginChecker={authService.login}
    />, [tabs]);


    return (
        <>
            {AppProvider}
        </>
    );
};

export default App;
