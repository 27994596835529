import {IAuthUser, ServerResponse} from '@gomarketsapp/ready-steady-go'
import axios, { AxiosResponse } from "axios";
import { User } from "models";
import { axiosSecured } from "utils";
import { URLs } from "../";

export const login = async (): Promise<ServerResponse<IAuthUser, {}>> => {
  const res = await axios.get(URLs.login as string) as AxiosResponse;
  return res.data
}

export const authenticateWithToken = async (): Promise<ServerResponse<User>> => {
  const res = await axiosSecured(URLs.login as string) as AxiosResponse<ServerResponse<User>>
  return res.data
}

export const signOutUser = async (): Promise<void> => {
  await axiosSecured(URLs.logout as string)
  localStorage.removeItem('jwt')
}
