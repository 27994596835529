import {AxiosResponse} from 'axios';
import {axiosSecured} from 'utils';
import {LogProps, ServerResponse} from 'models';
import adminPortal from './adminPortal';
import appBackend from './appBackend';
import appCompanyPortal from './appCompanyPortal';
import {URLs} from "../urls";

const logsServices = {
    ...adminPortal,
    ...appBackend,
    ...appCompanyPortal,

    retrieveLogDetails: async (
        table: string, //Add this params to the actual api
        applicantId: string //Add this params to the actual api
    ): Promise<ServerResponse<LogProps[]>> => {
        const res = await axiosSecured({
            url: (URLs.getLogDetails as (p: string) => string)(`${table}/${applicantId}`)
        }) as AxiosResponse;

        return res.data;
    },
    updateOneApplication: async (
        data: string,
        ref_id: string
    ): Promise<ServerResponse> => {
        const res = await axiosSecured.patch(
            (URLs.updateLog as (p: string) => string)(`${ref_id}`),
            data
        ) as AxiosResponse;

        return res.data;
    },

    syncLogs: async (
        appName: 'admin' | 'manage' | 'company'
    ): Promise<ServerResponse<{
        synced: string[];
        remains: string[];
    }>> => {
        const res = await axiosSecured.get((URLs.syncLogs as (appName: 'admin' | 'manage' | 'company') => string)(appName)) as AxiosResponse;

        return res.data;
    },
};

export default logsServices;
