import React, {FC} from 'react';
import {logsService, URLs} from 'services';
import {Heading} from '@chakra-ui/react';
import LogsTable from "../LogsTable";

const ApplicationBackendPortalLIVE: FC = () => {
    const {retrieveApplicationBackendLogs} = logsService;

    const fetcherKey = `${URLs.getBackendPortalLogs}` as string;

    return (
        <>
            <Heading size="lg" mb={4}>
                Application Backend Portal Logs
            </Heading>
            <LogsTable fetcherKey={fetcherKey}
                       service={(paginationOptions) => retrieveApplicationBackendLogs(paginationOptions, ['groups'])}
                       platformKey={'manage'}/>
        </>
    );
};

export default ApplicationBackendPortalLIVE;
