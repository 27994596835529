import React, {FunctionComponent} from 'react';
import {LogProps} from 'models';
import {app_number, process_group_id} from 'utils';
import {useNavigate} from 'react-router-dom';
import {IColumn, IPaginationRequest, IPaginationResponse, ServerResponse, GoTable} from '@gomarketsapp/ready-steady-go';
import {columnsList} from "./tableColumns";
import {Button} from "primereact/button";
import {SyncButton} from "./SyncButton";


interface LogsTableProps {
    fetcherKey: string;

    service(paginationOptions: IPaginationRequest, groupBy?: string[]): Promise<ServerResponse<IPaginationResponse<LogProps[]>>>;

    platformKey: 'admin' | 'manage' | 'company';
}

const LogsTable: FunctionComponent<LogsTableProps> = ({fetcherKey, service, platformKey}) => {
    const navigate = useNavigate();


    const onRowClick = (row: LogProps, separate:boolean = false) => {

        let url:string = '';
        const groupIdLength = row[process_group_id].length;

        if (groupIdLength > 0 && groupIdLength < 37) {
            url = `/${platformKey}/${row[process_group_id]}`;
        } else {
            url = `/${platformKey}/${row[app_number]}`;
        }

        if(url){
            if(separate){
                window.open(`${window.location.origin}${url}`, "_blank");
            }else{
                navigate(url)
            }
        }

    };

    const actions = {
        label: '#',
        name: 'uuid',
        body: (value, data) => {
            return <Button
                type="button"
                size={'small'}
                label={'Details'}
                text
                style={{height:'100%'}}
                onMouseDown={(event)=> onRowClick(data, event.button == 1)}
            />
        }
    } as IColumn<LogProps>

    return (
        <div className="card" style={{maxHeight:'80vh'}}>
            <GoTable id={fetcherKey}
                       keyColumn={'id'}
                       scrollHeight={'79vh'}
                       moreButtons={(context) => {
                           return [<SyncButton appName={platformKey} mutate={context.refresh}/>]
                       }}
                       service={service}
                       columns={[...columnsList, actions]}
            />
        </div>
    );
};

export default LogsTable;
