export const app_number = 'uuid';
export const route = 'route';
export const file_name = 'file_name';
export const function_name = 'function_name';
export const function_details = 'function_details';
export const process_name = 'process_name';
export const processes = 'processes';
export const process_group_id = 'group_id';
export const methods_number = 'methods_number';
export const status = 'status';
export const created_at = 'created_at';
export const recorded_at = 'recorded_at';
export const logged_user_email = 'logged_user_email';
export const message = 'message';
export const additional_data = 'custom_data';
export const error_details = 'error_details';
export const type = 'type';
export const account = 'account';



export const getVal = (
  obj: any,
  key: string,
  defaultValue: string | [] = ''
) => {
  return obj && typeof obj == 'object' && obj.hasOwnProperty(key) && obj[key]
    ? obj[key]
    : defaultValue;
};
