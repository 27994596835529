import React, { Suspense } from 'react'
import App from './App'
import {ChakraProvider} from '@chakra-ui/react'
import {SplashScreen} from "@gomarketsapp/ready-steady-go";



export default ()=>  <ChakraProvider>
     <Suspense fallback={<SplashScreen />}>
         <App />
     </Suspense>
 </ChakraProvider>



