import React, {FC} from 'react';
import {logsService, URLs} from 'services';
import {Heading} from '@chakra-ui/react';
import LogsTable from "../LogsTable";

const AdminPortalLIVE: FC = () => {
    const {retrieveAdminPortalLogs} = logsService;

    const fetcherKey = `${URLs.getAdminPortalLogs}` as string;

    return (
        <>
            <Heading size="lg" mb={4}>
                Admin Portal Logs
            </Heading>
            <LogsTable fetcherKey={fetcherKey}
                       service={(paginationOptions) => retrieveAdminPortalLogs(paginationOptions, ['groups'])}
                       platformKey={'admin'}
            />
        </>
    );
};

export default AdminPortalLIVE;
