import {LogProps} from "models";
import moment from "moment";
import {
    additional_data,
    function_name,
    getStatues,
    logged_user_email,
    processes,
    recorded_at,
    route,
    status
} from "utils";
import {Icon} from "@chakra-ui/icons";
import {Button} from "primereact/button";
import React from "react";
import {IColumn, Text} from "@gomarketsapp/ready-steady-go";


const CircleIcon = (props) => (
    <Icon viewBox='0 0 200 200' {...props}>
        <path
            fill='currentColor'
            d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
        />
    </Icon>
)

const emailCol = {
    label: 'Email',
    name: logged_user_email,
    // Filter: (props: FilterProps<LogProps>) => (
    //     <TableInstance.ColumnFilter {...props} type="text" placeholder="Email" />
    // )
    filter: {
        type: 'text'
    }
} as IColumn<LogProps>

const accountCol = {
    label: 'Account',
    name: 'custom_data',
    // Filter: (props: FilterProps<LogProps>) => (
    //     <TableInstance.ColumnFilter {...props} type="text" placeholder="Email" />
    // )
    isSortable: true,
    filter: {
        type: 'text'
    },
    body: (value, row) => {
        if (row[additional_data] && typeof row[additional_data] === 'object' && 'account' in row[additional_data]){
            return value['account']
        }
        return <Text textAlign={'center'}>N/A</Text>
    }
} as IColumn<LogProps>

const processCol = {
    label: 'Process Name',
    name: processes,
    body: (value) => {
        const processesArray = value.split(',')
        return <Button
            type="button"
            style={{
                background: 'transparent',
                border: 'none',
                color: 'black'
            }}
            label={processesArray.length.toString()}
            tooltip={processesArray.join('\n')}
            tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}}
        />

    },
    filter: {
        type: 'text'
    }
} as IColumn<LogProps>

const statusCol = {
    label: 'Status',
    name: status,
    // disableSortBy: true,
    body: (value) => {
        return (
            <div>{getStatues(value.split(",")).map((n, index) => {
                return <CircleIcon key={index}  {...n as object} />;
            })}</div>
        );
    },

    filter: {
        type: "dropdown",
        list: [
            {
                label: "No Filter",
                value: null
            },
            {
                label: "Success",
                value: "success"
            },
            {
                label: "Notice",
                value: "notice"
            },
            {
                label: "Info",
                value: "info"
            },
            {
                label: "Emergency",
                value: "emergency"
            },
            {
                label: "Alert",
                value: "alert"
            },
            {
                label: "Critical",
                value: "critical"
            },
            {
                label: "Error",
                value: "error"
            },
            {
                label: "Warning",
                value: "warning"
            }

        ]
    },
} as IColumn<LogProps>

const createdAtCol = {
    label: 'Date',
    name: recorded_at,
    isSortable: true,
    // defaultCanSort: true,
    body: (value) => {
        return (
            <div> {moment(new Date(value)).format('DD/MM/yyyy hh:mm')} </div>
        );
    },
    // Filter: (props: FilterProps<LogProps>) => (
    //     <TableInstance.ColumnFilter {...props} type="date"/>
    // ),
    filter: {
        type: 'date',
    },
} as IColumn<LogProps>


const routeCol = {
    label: 'Route',
    name: route,
    // disableSortBy: true,
    filter: {
        type: 'text'
    }
} as IColumn<LogProps>


const functionNameCol = {
    label: 'Function Name',
    // disableSortBy: true,
    name: function_name,
    filter: {
        type: 'text'
    }
} as IColumn<LogProps>


export default [
    accountCol, statusCol, emailCol, createdAtCol, routeCol, processCol, functionNameCol,
]

