import {AxiosResponse} from 'axios';
import {axiosSecured} from 'utils';
import {FetchTableDataRes, LogProps} from 'models';
import {URLs} from "../urls";
import {IPaginationRequest, IPaginationResponse, ServerResponse} from "@gomarketsapp/ready-steady-go";


const logsServices = {

  retrieveApplicationBackendLogs: async (
      paginationOptions: IPaginationRequest,
      groupBy:string[] = [],
  ): Promise<
      ServerResponse<IPaginationResponse<LogProps[]>>
      > => {

    const res = await axiosSecured.post(`${URLs.getBackendPortalLogs}` as string, { ...paginationOptions,groupBy }) as AxiosResponse;

    return res.data;
  },

};

export default logsServices;
