import {LegacyRef, useCallback, useRef, useState} from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    useToast,
} from '@chakra-ui/react'

import {IPromptSettings, ServerResponse} from 'models';
import React from 'react';

export const Alert = () => {
    const toast = useToast();

    const successAlert = (res: ServerResponse) => {
        toast({
            title: '',
            description: res?.message,
            status: 'success',
            duration: 3000,
            isClosable: true
        });
    };

    const errorAlert = (res: ServerResponse) => {
        toast({
            title: '',
            description: res?.message,
            status: 'error',
            duration: 3000,
            isClosable: true
        });
    };

    const {isOpen, onOpen, onClose} = useDisclosure();
    const cancelRef = useRef();

    const [promptSettings, setPromptSettings] = useState<IPromptSettings>({
        title: '',
        body: '',
        size:'full',
        showCancel: true,
        cancelButtonTitle: 'No',
        onClickCancel: () => {
        },
        submitButtonTitle: 'Yes',
        onClickSubmit: () => {
        }
    });

    const toggleAlertDialog = useCallback(({...data}: IPromptSettings) => {
        isOpen ? onClose() : onOpen();
        setPromptSettings({
            ...promptSettings,
            ...data
        });
    }, []);

    const AlertDialog = ({submitButtonColorSchema = 'green'}) => {
        return (
            <Modal
                size={promptSettings.size}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader fontSize="lg" fontWeight="bold">
                        {promptSettings.title}
                    </ModalHeader>

                    <ModalCloseButton/>
                    <ModalBody>{promptSettings.body}</ModalBody>

                    <ModalFooter>
                        {promptSettings.showCancel && <Button
                            ref={cancelRef as unknown as LegacyRef<HTMLButtonElement>}
                            onClick={() => {
                                promptSettings.onClickCancel &&
                                promptSettings.onClickCancel();
                                onClose();
                            }}
                        >
                            {promptSettings.cancelButtonTitle}
                        </Button>}
                        <Button
                            colorScheme={submitButtonColorSchema}
                            onClick={() => {
                                promptSettings.onClickSubmit &&
                                promptSettings.onClickSubmit();
                                onClose();
                            }}
                            ml={3}
                        >
                            {promptSettings.submitButtonTitle}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        );
    };
    return {successAlert, errorAlert, AlertDialog, toggleAlertDialog};
};
