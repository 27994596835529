import axios from 'axios';
import { getUrl } from '@gomarketsapp/ready-steady-go';

axios.interceptors.response.use(
    response => response,
    error => ({
        ...error,
        data: {
            success: false,
            message: error.response?.data?.message || 'Server Error'
        }
    })
)

// const state = configureStore.store.getState();
// console.log(state)
axios.defaults.withCredentials = true;
axios.interceptors.request.use(
    async config => {

        if (config.url && !config.url.includes('abr.business.gov.au') && !config.url.includes('experianaperture.io')) {

            const jwt = localStorage.getItem('jwt')
            // @ts-ignore
            config.baseURL = getUrl('api')


            if(jwt){
                config.headers.Authorization = `Bearer ${jwt}`
            }else{
                window.location.reload()
            }

        }

        return config
    },
    error => Promise.reject(error)
)

export const axiosSecured = axios