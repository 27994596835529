import React, {FunctionComponent, useState} from 'react';
import {file_name, function_details, function_name, logged_user_email, message, process_name, route, type} from "utils";
import {LogProps} from "models";
import {Button, Container, Flex, Text} from "@gomarketsapp/ready-steady-go";

type Props = {
    index: number,
    log: LogProps,
    onReadButton:()=>void
};

const LogItem: FunctionComponent<Props> = ({index, log, onReadButton}) => {

    const errorCodes = [
        "CRITICAL_ERROR", "THROWN_ERROR", "FAILED", 'emergency', 'alert', 'critical', 'error', 'warning'
    ]
    const [notes, setNotes] = useState("");

    const getFunctionLine = () => {
        const functionDetails = log[function_details];
        let functionName = log[function_name];
        if (functionDetails && Array.isArray(functionDetails)) {
            functionName = functionDetails.map(f => {
                return f.function + "()"
            }).join(" -> ");
        }

        return functionName;
    }

    const Row = ({label, value}: { label: string; value: string; }) => {
        return <Flex gap={5}
                     whiteSpace={'nowrap'}
                     overflow={'hidden'}
                     textOverflow={'ellipsis'}
                     w={'100%'}
        >
            <Text fontSize={'md'}>{label}</Text>
            <Text
                fontSize={'md'}>{value.replace('/home/admingomarketsco/public_html/serverside', '')}</Text>

        </Flex>
    }

    const standardDetails = () => {
        return (
            <Flex flexDirection={'column'} ml='2' width={'100%'} gap={8}>
                <Row label={"Route:"} value={log[route]}/>
                <Row label={"File Name:"} value={log[file_name]}/>
                <Row label={"Functions Flow:"} value={getFunctionLine()}/>
                <Row label={"Process:"} value={log[process_name]}/>
                {log[type] && <Row label={"Type:"} value={log[type]}/>}
                {log[logged_user_email] && <Row label={"User Email:"} value={log[logged_user_email]}/>}
                {log[message] && <Row label={"Message:"} value={log[message]}/>}
                {/*<Row label={"Status:"} value={<Badge style={{
                    backgroundColor:iconsStatusColors[log[status]]
                }} color={'white'}>
                    {log[status]}
                </Badge>}/>*/}


                {/*                {log[additional_data] &&
                    <Row label={"Data:"}
                         value={
                             Array.isArray(log[additional_data]) ?
                                 // @ts-ignore
                                 <ReactJson src={log[additional_data]}/> :
                                 // @ts-ignore
                                 <ReactJson src={[log[additional_data]]}/>}/>}*/}


            </Flex>
        )
    }


    return (
        <Container title={log[process_name]}
                   key={index} ml={'2'}
                   width={'100%'}
                   rightElement={<Button label={"Read"}
                                         iconPos={'right'}
                                         icon={'pi pi-angle-double-right'}
                                         onClick={onReadButton}/>}
                   childrenContainerProps={{maxW:'full'}}>
            {standardDetails()}

        </Container>
    );
};

export default LogItem;
