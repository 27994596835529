import {CustomPopover, Flex, Text, useThrottle} from '@gomarketsapp/ready-steady-go';
import React, {useState} from "react";
import {logsService} from "services";
import {PiCheck} from "react-icons/pi";
import {BiHourglass} from "react-icons/bi";
import {ProgressSpinner} from "primereact/progressspinner";
import {values} from "lodash";

interface ITableContext {
    appName: 'admin' | 'manage' | 'company'
    mutate: () => void
}

export const SyncButton = ({
                               appName,
                               mutate
                           }: Omit<ITableContext, 'tableInstance'>) => {
    const [isLoading, setIsLoading] = useState(false)
    const [syncedFiles, setSyncedFiles] = useState<string[]>([])
    const [remainsFiles, setRemainsFiles] = useState<string[]>([])


    const {syncLogs} = logsService

    const refresh = useThrottle(() => {
        mutate();
        return {success: true, message: 'Synced and Refreshed'};
    }, 2000);

    const onClick = async () => {
        setIsLoading(true);
        syncLogs(appName).then((res) => {

            if (res.success) {
                setSyncedFiles(prevState => ([
                    ...prevState,
                    ...(res.data?.synced) ?? []
                ]))

                setRemainsFiles((res.data?.remains) ?? [])

                if (res.data && res.data.remains.length > 0) {
                    setTimeout(() => {
                        onClick();
                    }, 100)
                } else {
                    setIsLoading(false)
                    refresh();
                }
            }
        })
            .catch(() => {
                setIsLoading(false)
                refresh();
            })

    }

    return (
        <CustomPopover label={'Sync'} onPopoverOpened={onClick}>
            <Flex flexDirection={'column'} w={300}>
                <Text as={'h3'}>Files Syncing</Text>
                <br/>
                <Flex flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
                    {syncedFiles?.length == 0 &&
                        <ProgressSpinner color={'green'} style={{width: 20, height: 20, margin: 0}}/>}
                </Flex>
                {
                    syncedFiles?.map((log, index) => (
                        <Flex key={index} gap={5} alignItems={'center'}>
                            <PiCheck color={'green'}/>
                            <Text>{log}</Text>
                        </Flex>
                    ))
                }
                {
                    remainsFiles?.map((log, index) => (
                        <Flex key={index} gap={5} alignItems={'center'}>
                            {index == 0 && isLoading &&
                                <ProgressSpinner color={'green'} style={{width: 14, height: 14, margin: 0}}/>}
                            {index > 0 && <BiHourglass color={'green'}/>}
                            <Text>{log}</Text>
                        </Flex>
                    ))
                }
            </Flex>
        </CustomPopover>
    )
    /*    return (
            <Button type="button"
                    icon={isLoading ? <Spinner size="sm" /> : <RepeatClockIcon />}
                    tooltipOptions={{
                        position: 'top',
                    }}
                    tooltip={"Sync and Refresh Table"}
                    size={'small'}
                    onClick={onClick}/>

        );*/
};
